<script>
  import { slide } from "svelte/transition";
  //DIDN'T WRITE THIS FUNCTION - GOT IT OFF THE INTERNET
  function getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  const queryMonth = getUrlParameter("q"); //USER QUERY PARAM FOR GOING THRU ARCHIVE MONTHS

  let userQuery = ""; //USER QUERY

  //ARRAY HOLDING EACH FINAL EDITED JSON OBJECT OF A PROJECT
  //THIS WILL BE USED TO LOOP THRU AND DISPLAY THE DATA
  let projects = [];

  let devProjects = [];

  let otherProjects = [];

  //THE NAMES OF THE 12 MONTHS
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //GETS CURRENT MONTH / YEAR
  let getCurrDate = () => {
    var d = new Date();
    var cm = d.getMonth();
    var cy = d.getFullYear();
    return months[cm] + " " + cy;
  };

  //GETS ALL THE CURRENT ASANA PROJECTS FROM THE API ROUTE PROJECTS

  //NORMAL PROJECTS
  async function getProjects() {
    const res = await fetch("/api/projects");
    const projectsJSON = await res.json();
    console.log(projectsJSON);
    if (res.ok) {
      for (let i = 0; i < projectsJSON.data.length; i++) {
        if (
          projectsJSON.data[i].name.includes("-") &&
          projectsJSON.data[i].name.includes("dev") == false &&
          projectsJSON.data[i].name.includes("Hosting") == false
        ) {
          let res = projectsJSON.data[i].name.split("-"); //DELIMITTER
          res[0] = res[0].trim(); //FIRST HALF - PROJECT NAME
          res[1] = res[1].trim(); //SECOND HALF - ALOTTED TIME
          let gid = projectsJSON.data[i].gid; //PROJECT ID
          let teamName = getTeam(gid).then((teams) => {
            return teams.data.team.name;
          });
          projects.push({
            name: res[0],
            maxTime: res[1],
            gid: gid,
            teamName: teamName,
            tts: getTTS(gid, queryMonth).then((tts) => {
              return tts.tts;
            }),
          });
        }
      }
      console.log(projects);
      return projects;
    } else {
      console.error("err");
    }
  }

  //DEV PROJECTS
  async function getDevProjects() {
    const res = await fetch("/api/projects");
    const projectsJSON = await res.json();
    if (res.ok) {
      for (let i = 0; i < projectsJSON.data.length; i++) {
        if (
          projectsJSON.data[i].name.includes("-") &&
          projectsJSON.data[i].name.includes("dev") == true &&
          projectsJSON.data[i].name.includes("Hosting") == false
        ) {
          let res = projectsJSON.data[i].name.split("-"); //DELIMITTER
          res[0] = res[0].trim(); //FIRST HALF - PROJECT NAME
          res[1] = res[1].trim(); //SECOND HALF - ALOTTED TIME
          let gid = projectsJSON.data[i].gid; //PROJECT ID
          let teamName = getTeam(gid).then((teams) => {
            return teams.data.team.name;
          });
          devProjects.push({
            name: res[0],
            maxTime: res[1],
            gid: gid,
            teamName: teamName,
            tts: getTTSDev(gid).then((tts) => {
              return tts.fpt;
            }),
          });
        }
      }
      console.log(devProjects);
      return devProjects;
    } else {
      console.error("err");
    }
  }

  //MISC PROJECTS
  async function getOtherProjects() {
    const res = await fetch("/api/projects");
    const projectsJSON = await res.json();
    if (res.ok) {
      for (let i = 0; i < projectsJSON.data.length; i++) {
        if (
          projectsJSON.data[i].name.includes("-") &&
          projectsJSON.data[i].name.includes("Hosting") == true
        ) {
          let res = projectsJSON.data[i].name.split("-"); //DELIMITTER
          res[0] = res[0].trim(); //FIRST HALF - PROJECT NAME
          res[1] = res[1].trim(); //SECOND HALF - ALOTTED TIME
          let gid = projectsJSON.data[i].gid; //PROJECT ID
          let teamName = getTeam(gid).then((teams) => {
            return teams.data.team.name;
          });
          otherProjects.push({
            name: res[0],
            maxTime: "Hosting",
            gid: gid,
            teamName: teamName,
            tts: getTTSDev(gid).then((tts) => {
              return tts.fpt;
            }),
          });
        }
      }
      console.log(otherProjects);
      return otherProjects;
    } else {
      console.error("err");
    }
  }

  getProjects();
  getDevProjects();
  getOtherProjects();

  //HANDLE USER SEARCH INPUT AND NAVIGATE TO SEARCH QUERY
  let queryFlag = false; //SEARCHES FOR BAD INPUT
  function searchHandler(e) {
    if (e.key === "Enter") {
      var checkQuery = userQuery.split(" ");
      if (checkQuery.length == 2) {
        queryFlag = true;
        for (let i = 0; i < months.length; i++) {
          if (months[i] == checkQuery[0]) {
            queryFlag = false;
          } else {
            queryFlag = true;
          }
        }
        if (checkQuery[1].length == 4 && isNaN(checkQuery[1]) == false) {
          queryFlag = true;
        } else {
          queryFlag = false;
        }
      } else {
        queryFlag = false;
      }
      if (queryFlag == true) {
        window.location.href = "/?q=" + userQuery;
      } else {
        window.location.href = "/";
      }
    }
  }

  //GET TOTAL MONTHLY TIME
  async function getTTS(uid, q) {
    if (q) {
      console.log(q);
      try {
        //q = q.replace(" ", "%20");
        let response = await fetch("/api/tasks/" + uid + "/?q=" + q);
        let data = response.json();
        return data;
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        let response = await fetch("/api/tasks/" + uid);
        let data = response.json();
        return data;
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function getTTSDev(uid, q) {
    try {
      let response = await fetch("/api/devTasks/" + uid);
      let data = response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  //GET TEAM NAME
  async function getTeam(uid) {
    try {
      let response = await fetch("/api/teams/" + uid);
      let data = response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  function sectionExpand() {
    if (this.style.height != "auto") {
      this.style.height = "100%";
      this.style.overflow = "none";
    } else if (this.style.height == "100%") {
      this.style.height = "55px";
      this.style.overflow = "hidden";
    }
  }

  let isActive = false;
  let isActiveDev = false;
  let isActiveOther = false;
</script>

<div class="menu-bar">
  <div class="menu-l">
    {#if queryMonth}
      <a>Searching in -</a>
      <a id="current-month" href="/">{queryMonth}</a>
    {:else}
      <a>Current Month -</a>
      <a id="current-month" href="/">{getCurrDate()}</a>
    {/if}
  </div>
  <div class="menu-c">
    {#if queryMonth}
      <input
        type="text"
        id="search-bar"
        placeholder="Searching projects in {queryMonth}"
        bind:value={userQuery}
        on:keyup={searchHandler}
      />
    {:else}
      <input
        type="text"
        id="search-bar"
        placeholder="Search by month / year - MMMM YYYY"
        bind:value={userQuery}
        on:keyup={searchHandler}
      />
    {/if}
  </div>
  <div class="menu-r">
    <a href="https://www.sucoweb.com/"
      ><img id="header-logo" src="/favicon.png" alt="suco" /></a
    >
  </div>
</div>

<div class="wrap-header" id="curr-menu" on:click={() => (isActive = !isActive)}>
  {#if isActive}
    <h1>💾 Current Projects - <span class="blue">Click to Minimize</span></h1>
  {:else}
    <h1>💾 Current Projects - <span class="blue">Click to Expand</span></h1>
  {/if}
</div>

{#if isActive}
  <div class="wrap" id="current-project" transition:slide>
    <table>
      <tr>
        <th>Project Name</th>
        <th>Team Name</th>
        <th>Max Monthly Time</th>
        <th>Actual Time Spent</th>
        <th>JSON</th>
      </tr>
      {#each projects as { name, maxTime, gid, teamName, tts }}
        <tr>
          <td
            ><a href="https://app.asana.com/0/{gid}" target="_blank"
              >{name} <i class="icon ion-ios-log-in" /></a
            ></td
          >
          <td class="teamName">
            {#await teamName}
              <div class="loading-wrap">
                <div class="loading" />
              </div>
            {:then value}
              {value}
            {:catch error}
              ...
            {/await}
          </td>
          <td>
            <a class="badge">{maxTime}h</a>
          </td>
          <td class="rem-time">
            {#await tts}
              <div class="loading-wrap">
                <div class="loading" />
              </div>
            {:then value}
              <a class="badge" href="/">
                {Math.trunc(value / 60)}h {value % 60}m
              </a>
            {:catch error}
              ...
            {/await}
          </td>
          <td>
            <a class="view-json" href="/api/tasks/{gid}" target="_blank">View</a
            >
          </td>
        </tr>
      {/each}
    </table>
  </div>
{/if}

<div
  class="wrap-header"
  id="dev-menu"
  on:click={() => (isActiveDev = !isActiveDev)}
>
  {#if isActiveDev}
    <h1>
      🚧 Current Dev Projects - <span class="blue">Click to Minimize</span>
    </h1>
  {:else}
    <h1>🚧 Current Dev Projects - <span class="blue">Click to Expand</span></h1>
  {/if}
</div>

{#if isActiveDev}
  <div class="wrap" id="dev-project" transition:slide>
    <table>
      <tr>
        <th>Project Name</th>
        <th>Team Name</th>
        <th>Max Monthly Time</th>
        <th>Actual Time Spent</th>
        <th>JSON</th>
      </tr>
      {#each devProjects as { name, maxTime, gid, teamName, tts }}
        <tr>
          <td
            ><a href="https://app.asana.com/0/{gid}" target="_blank"
              >{name} <i class="icon ion-ios-log-in" /></a
            ></td
          >
          <td class="teamName">
            {#await teamName}
              <div class="loading-wrap">
                <div class="loading" />
              </div>
            {:then value}
              {value}
            {:catch error}
              ...
            {/await}
          </td>
          <td>
            <a class="badge">{maxTime}</a>
          </td>
          <td class="rem-time">
            {#await tts}
              <div class="loading-wrap">
                <div class="loading" />
              </div>
            {:then value}
              <a class="badge" href="/">
                {Math.trunc(value / 60)}h {value % 60}m
              </a>
            {:catch error}
              ...
            {/await}
          </td>
          <td>
            <a class="view-json" href="/api/tasks/{gid}" target="_blank">View</a
            >
          </td>
        </tr>
      {/each}
    </table>
  </div>
{/if}

<div
  class="wrap-header"
  id="other-menu"
  on:click={() => (isActiveOther = !isActiveOther)}
>
  {#if isActiveOther}
    <h1>
      🌋 Other Projects - <span class="blue">Click to Minimize</span>
    </h1>
  {:else}
    <h1>🌋 Other Projects - <span class="blue">Click to Expand</span></h1>
  {/if}
</div>

{#if isActiveOther}
  <div class="wrap" id="other-project" transition:slide>
    <table>
      <tr>
        <th>Project Name</th>
        <th>Team Name</th>
        <th>Max Monthly Time</th>
        <th>Actual Time Spent</th>
        <th>JSON</th>
      </tr>
      {#each otherProjects as { name, maxTime, gid, teamName, tts }}
        <tr>
          <td
            ><a href="https://app.asana.com/0/{gid}" target="_blank"
              >{name} <i class="icon ion-ios-log-in" /></a
            ></td
          >
          <td class="teamName">
            {#await teamName}
              <div class="loading-wrap">
                <div class="loading" />
              </div>
            {:then value}
              {value}
            {:catch error}
              ...
            {/await}
          </td>
          <td>
            <a class="badge">{maxTime}</a>
          </td>
          <td class="rem-time">
            {#await tts}
              <div class="loading-wrap">
                <div class="loading" />
              </div>
            {:then value}
              <a class="badge" href="/">
                {Math.trunc(value / 60)}h {value % 60}m
              </a>
            {:catch error}
              ...
            {/await}
          </td>
          <td>
            <a class="view-json" href="/api/tasks/{gid}" target="_blank">View</a
            >
          </td>
        </tr>
      {/each}
    </table>
  </div>
{/if}
